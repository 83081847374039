import React from 'react'



export class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return {error}
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
    console.log(errorInfo)
  }
  render() {
    if (this.state && this.state.error) {
      return <div>An error occurred</div>
    }
    return this.props.children || null
  }
}