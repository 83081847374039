import gql from 'graphql-tag'

export const ErrorFragment = gql`
 fragment ErrorTypeFragment on ErrorsType {
    errors {
        field
        messages
    }
}
`

export const ApplicationNodeFragment = gql`
  fragment ApplicationNodeFragment on ApplicationNode {
        id
        key
        name
        userCount
    }
`

export const TokenFragment = gql`
  fragment TokenFragment on TokenNode {
      id     
      key
      created
      type  
  }
`

export const MeFragment = gql`
  fragment MeFragment on MeType {
    name
    email
  
  }
`