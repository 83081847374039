import React from "react";
import moment from "moment";
import gql from "graphql-tag";
import Spinner from "../Spinner";
import { Classes, Divider, H2, Button, Dialog } from "@blueprintjs/core";
import { graphql, compose } from "react-apollo";

import { PlanPicker, PlanPricing } from "./plan";
import { ErrorFragment } from "../../services/DeveloperFragments";

import { HiddenActionHoverTarget, HiddenActions } from "../hidden-actions";

const SubscriptionStatus = {
  ACTIVE: "ACTIVE"
};

const query = gql`
  {
    getPlans {
      edges {
        node {
          id
          active
          name
          description
          livemode
          billingScheme
          interval
          nickname
          amount
          #          aggregateUsage
          tiers
          trialPeriodDays
          metadata
          tiersMode
          statementDescriptor
        }
      }
    }

    getSubscriptions {
      edges {
        node {
          plan {
            id
            nickname
            usageType
            interval
            tiers
            trialPeriodDays
            interval
            tiersMode
          }
          currentPeriodEnd
          currentPeriodStart
          canceledAt
          cancelAtPeriodEnd
          created
          id
          endedAt
          start
          status
          description
          quantity
          billing
          livemode
          daysUntilDue
          trialEnd
          trialStart
        }
      }
    }
  }
`;

export const Mutations = {
  addSubscription: gql`
    mutation addSubscription($planNickname: String!) {
      addSubscription(planNickname: $planNickname) {
        ... on ErrorsType {
          ...ErrorTypeFragment
        }
      }
    }

    ${ErrorFragment}
  `,
  cancelSubscriptions: gql`
    mutation cancelSubscriptions {
      cancelSubscriptions {
        ... on ErrorsType {
          ...ErrorTypeFragment
        }
      }
    }
    ${ErrorFragment}
  `
};

const getActive = subscriptions =>
  subscriptions.filter(s => s.status === SubscriptionStatus.ACTIVE);

const getInactive = subscriptions =>
  subscriptions.filter(s => s.status !== SubscriptionStatus.ACTIVE);

const getCanceled = subscriptions =>
  subscriptions.filter(s => s.canceledAt !== null);

const hasTrial = subscription => subscription.trialStart !== null;

const hasactiveTrial = subscription =>
  subscription.trialStart &&
  subscription.trialEnd &&
  moment().isBefore(subscription.trialEnd) &&
  moment().isAfter(subscription.trialStart);

export class Subscription extends React.Component {
  render() {
    console.log(this.props);
    return (
      <div
        style={{
          // display: 'inline-block',
          padding: "15px 10px",
          margin: "10px 0",
          border: `2px solid ${"#EEE"}`,
          borderRadius: 8
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <h4 style={{ padding: 0, margin: 0, textTransform: "capitalize" }}>
              {this.props.plan.nickname} Plan
            </h4>

            <div>{this.props.plan.amount}</div>
            <PlanPricing
              tiersMode={this.props.plan.tiersMode}
              interval={this.props.plan.interval}
              tiers={this.props.plan.tiers}
              amount={this.props.plan.amount}
              billingScheme={this.props.plan.billingScheme}
            />

            <span>
              current period ends{" "}
              {moment(this.props.currentPeriodEnd).fromNow()}
            </span>

            {this.props.canceledAt && (
              <div>
                canceled {moment(this.props.canceledAt).fromNow()}
                {this.props.cancelAtPeriodEnd
                  ? `, subscription will end at end of current period`
                  : `, subscription is ended`}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export class _Subscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCancelDialog: false,
      showPlanPicker: false
    };
  }

  hideCancelDialog = () => this.setState({ showCancelDialog: false });
  showCancelDialog = () => this.setState({ showCancelDialog: true });
  hidePlanPicker = () => this.setState({ showPlanPicker: false });
  showPlanPicker = () => this.setState({ showPlanPicker: true });

  cancelSubscriptions = async () => {
    await this.props.cancelSubscriptions();
    this.props.data.refetch();
  };

  subscribe = async plan => {
    await this.props.addSubscription({
      variables: {
        planNickname: plan.nickname
      }
    });

    this.props.data.refetch();
  };

  render() {
    const isLoading = this.props.data && this.props.data.loading;
    if (isLoading) return <Spinner />;

    const subscriptions = this.props.data.getSubscriptions
      ? this.props.data.getSubscriptions.edges.map(({ node }) => node)
      : [];

    const activeSubscriptions = getActive(subscriptions);
    const pastSubscriptions = getInactive(subscriptions);

    const canceledActiveSubscriptions = getCanceled(activeSubscriptions);
    const canCancelSubscriptions =
      activeSubscriptions.length > canceledActiveSubscriptions.length;

    const plans = this.props.data.getPlans.edges.map(({ node }) => node);

    return (
      <div>
        {activeSubscriptions.map(s => (
          <Subscription {...s} />
        ))}

        {activeSubscriptions.length === 0 && (
          <div>
            <p>
              You don't have an active subscription, click here to pick a plan.
            </p>
            <Button onClick={this.showPlanPicker}>Pick a Plan</Button>
          </div>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/*{activeSubscriptions.length > 0 && (*/}
          {/*  <Button minimal intent={"success"} onClick={this.showPlanPicker}>*/}
          {/*    Change Plan*/}
          {/*  </Button>*/}
          {/*)}*/}

          {canCancelSubscriptions && (
            <Button minimal onClick={this.showCancelDialog}>
              Cancel Subscription
            </Button>
          )}
        </div>

        {/*{pastSubscriptions.length > 0 && (*/}
        {/*  <div>*/}
        {/*    <p>previous subscriptions</p>*/}

        {/*    {pastSubscriptions.map(s => (*/}
        {/*      <Subscription {...s} />*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}

        <Dialog
          title={"Cancel Subscription"}
          isOpen={this.state.showCancelDialog}
          onClose={this.hideCancelDialog}
        >
          <div className={Classes.DIALOG_BODY}>
            <p>
              Are you sure you want to cancel your subscription? Doing so will
              disable your API access at the end of the current term.
            </p>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.cancelSubscriptions}>
                Yes, cancel my subscriptions
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          title={"Select Plan"}
          isOpen={this.state.showPlanPicker}
          onClose={this.hidePlanPicker}
        >
          <div className={Classes.DIALOG_BODY}>
            <PlanPicker plans={plans} onSelect={this.subscribe} />
          </div>
        </Dialog>
      </div>
    );
  }
}

export const Subscriptions = compose(
  graphql(query),
  graphql(Mutations.cancelSubscriptions, { name: "cancelSubscriptions" }),
  graphql(Mutations.addSubscription, { name: "addSubscription" })
)(_Subscriptions);
