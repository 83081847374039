import React from "react";

import { Divider, H2 } from "@blueprintjs/core";
import DashboardLayout from "../components/layout/dashboard";
import gql from "graphql-tag";

import { ErrorBoundary } from "../components/errors";
import { Invoices } from "../components/billing/invoice";
import { PaymentMethods } from "../components/billing/payment";
import { Subscriptions } from "../components/billing/subscription";

const BillingQuery = gql`
  {
    getCards {
      edges {
        node {
          brand
          name
          id
        }
      }
    }
  }
`;

export default class Billing extends React.Component {
  subscribe = async plan => {
    await this.props.addSubscription({
      variables: {
        planNickname: plan
      }
    });

    this.props.data.refetch();
  };

  render() {
    return (
      <DashboardLayout title="Billing">
        <div id={"subscriptions"}>
          <H2>Subscriptions</H2>
          <Divider />
          <ErrorBoundary>
            <Subscriptions />
          </ErrorBoundary>
        </div>

        <div id={"invoices"}>
          <H2>Invoices</H2>
          <Divider />
          <ErrorBoundary>
            <Invoices />
          </ErrorBoundary>
        </div>

        <div id={"payment-methods"}>
          <H2>Payment Methods</H2>
          <Divider />
          <ErrorBoundary>
            <PaymentMethods />
          </ErrorBoundary>
        </div>
      </DashboardLayout>
    );
  }
}
