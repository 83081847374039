import React from "react";

export const RaisedContainer = ({
  children,
  style,
  padded = false,
  ...rest
}) => (
  <div
    style={{
      border: "1px solid #EEE",
      borderRadius: 3,
      boxShadow: "0px 10px 45px -16px rgba(0,0,0,0.1)",
      margin: "20px 0px",
      padding: padded ? "15px 25px" : 0,
      ...style
    }}
  >
    {children}
  </div>
);
