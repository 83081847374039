import React, { useState } from "react";

import {
  Button,
  Dialog,
  Divider,
  H2,
  HTMLTable,
  Intent,
  Icon
} from "@blueprintjs/core";

import moment from "moment";

import Spinner from "../Spinner";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";

import { RaisedContainer } from "../raised-container";

const query = gql`
  {
    getInvoices {
      edges {
        node {
          id
          number
          periodStart
          periodEnd
          livemode
          subscription {
            id
            plan {
              id
              nickname
            }
          }
          created
          date
          dueDate
          metadata
          description
          statementDescriptor
          amountDue
          amountPaid
          total
          startingBalance
          endingBalance
          attempted
          attemptCount
          nextPaymentAttempt
          closed
          forgiven
          hostedInvoiceUrl
          invoicePdf
          paid
        }
      }
    }
  }
`;

const formatMoney = amount => `$${amount.toFixed(2)}`;

const Status = {
  Paid: "Paid",
  Due: "Due",
  PastDue: "Past Due"
};

const Pill = ({ children, color }) => (
  <span
    style={{
      padding: "4px 7px",
      backgroundColor: color,
      // fontColor: '#069a00',
      borderRadius: 30,
      fontWeight: 500,
      fontSize: ".8rem"
    }}
  >
    {children}
  </span>
);

Pill.Colors = {
  Success: "#bfffb6",
  Warning: "#ffb08e",
  Default: "#eef9f7"
};

const StatusColors = {
  [Status.Paid]: Pill.Colors.Success,
  [Status.Due]: Pill.Colors.Default,
  [Status.PastDue]: Pill.Colors.Warning
};

export const Invoice = ({
  name,
  number,
  status,
  amountDue,
  amountPaid,
  periodStart,
  periodEnd,
  invoicePdf,
  hostedInvoiceUrl,
  paidDate,
  paymentMethod,
  plan,
  paid,
  dueDate
} = {}) => {
  const [expanded, updateExpanded] = useState(false);
  return (
    <>
      <tr
        onClick={() => updateExpanded(!expanded)}
        style={{ borderBottom: "1px solid #EEE", cursor: "pointer" }}
      >
        <td>
          {moment(periodStart).format("L")} - {moment(periodStart).format("L")}
        </td>
        <td>{number}</td>
        <td>{formatMoney(amountDue)}</td>
        <td>
          <Pill color={StatusColors[paid ? Status.Paid : Status.Due]}>
            {paid ? Status.Paid : Status.Due}
          </Pill>
        </td>
      </tr>
      {expanded && (
        <tr>
          <td colSpan="4">
            <div style={{ padding: 10 }}>
              {invoicePdf && <a href={invoicePdf}>Invoice PDF</a>}
              {hostedInvoiceUrl && <a href={hostedInvoiceUrl}>View Invoice</a>}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

Invoice.Status = Status;

export const InvoiceTable = ({ invoices }) => {
  return (
    <RaisedContainer>
      <HTMLTable
        style={{
          width: "100%",
          borderBottom: "1px solid #EEE",
          borderCollapse: "collapse"
        }}
        // striped
      >
        <thead>
          <tr>
            <th>Period</th>
            <th>Number</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {invoices.length ? (
            invoices.map(invoice => <Invoice {...invoice} />)
          ) : (
            <tr>
              <td colSpan="4">No invoices found</td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
      {/*<div style={{ padding: 10 }}>load more</div>*/}
    </RaisedContainer>
  );
};

export class _Invoices extends React.Component {
  render() {
    const isLoading = !this.props.data || this.props.data.loading;

    if (isLoading) return <Spinner />;

    return (
      this.props.data &&
      this.props.data.getInvoices && (
        <InvoiceTable
          invoices={this.props.data.getInvoices.edges.map(e => e.node)}
        />
      )
    );
  }
}

export const Invoices = compose(graphql(query))(_Invoices);
