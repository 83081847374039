import React from "react";
import { CardElement } from "react-stripe-elements";
import moment from "moment";
import { STRIPE_API_KEY } from "../../constants";
import { StripeProvider, Elements, injectStripe } from "react-stripe-elements";
import { Button, Tooltip } from "@blueprintjs/core";
import Spinner from "../Spinner";

import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import { ErrorFragment } from "../../services/DeveloperFragments";

import { RaisedContainer } from "../raised-container";

import "./payment.css";

const query = gql`
  {
    getSources {
      edges {
        node {
          id
          sourceId
          #          clientSecret
          sourceData
          type
          status
          created
        }
      }
    }

    getCards {
      edges {
        node {
          brand
          name
          id
        }
      }
    }
  }
`;

export const Mutations = {
  addSource: gql`
    mutation($sourceId: String!) {
      addSource(sourceId: $sourceId) {
        ... on ErrorsType {
          ...ErrorTypeFragment
        }
        ... on SourceNode {
          sourceId
          clientSecret
        }
      }
    }
    ${ErrorFragment}
  `,
  removeSource: gql`
    mutation($id: ID!) {
      removeSource(id: $id) {
        ... on ErrorsType {
          ...ErrorTypeFragment
        }
      }
    }

    ${ErrorFragment}
  `
};

export class _AddCardForm extends React.Component {
  static defaultProps = {
    buttonText: "Add Card"
  };

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      error: undefined
    };
  }

  handleChange = e => {
    if (e.error) {
      this.setState({ error: e.error });
    } else {
      this.setState({ error: undefined });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { source, error } = await this.props.stripe.createSource({
      type: "card"
    });

    if (source && this.props.onSourceCreated) {
      await this.props.onSourceCreated(source);
    }

    if (error && this.props.onError) {
      console.error(error);
      this.props.onError(error);
      this.setState({ error });
    }

    this.setState({ isSubmitting: false });
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          style={{
            // backgroundColor: '#f6f6f6',
            border: "1px solid #DADADA",
            boxShadow: "0px 10px 45px -16px rgba(0,0,0,0.1)",
            borderRadius: 3,
            padding: 14
            // margin: 6
          }}
        >
          <CardElement
            onChange={this.handleChange}
            style={{
              base: {
                fontSize: "16px"
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16
          }}
        >
          <div>{this.state.error && this.state.error.message}</div>

          <Button loading={this.state.isSubmitting} type={"submit"}>
            {this.props.buttonText}
          </Button>
        </div>
      </form>
    );
  }
}

// injectStripe has to be on the form and put inside <Elements> component
const AddCardFormWithStripe = injectStripe(_AddCardForm);

export const AddCardForm = ({ stripeApiKey = STRIPE_API_KEY, ...rest }) => (
  <StripeProvider apiKey={stripeApiKey}>
    <Elements>
      <AddCardFormWithStripe {...rest} />
    </Elements>
  </StripeProvider>
);

export class AddPaymentSource extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded || false
    };
  }
  render() {
    return (
      <div
        style={{
          // display: 'flex',
          // justifyContent: 'space-between',
          borderBottom: "1px solid #EEE",
          padding: "15px 20px"
          // boxShadow: '0px 10px 45px -16px rgba(0,0,0,0.1)'
        }}
      >
        {this.state.expanded ? (
          <AddCardForm onSourceCreated={this.props.onSourceCreated} />
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button fill onClick={e => this.setState({ expanded: true })}>
              Add New Payment Method
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export class PaymentSource extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isRemoving: false,
      error: undefined
    };
  }

  handleRemove = async () => {
    this.setState({ isRemoving: true, error: undefined });

    if (this.props.onRemove) {
      try {
        await this.props.onRemove(this);
      } catch (error) {
        this.setState({
          isRemoving: false,
          error
        });
      }
    }

    this.setState({ isRemoving: false });
  };

  render() {
    return (
      <div
        className={"payment-source"}
        style={{
          display: "flex",
          borderBottom: "1px solid #EEE",
          padding: "15px 20px",
          position: "relative"
        }}
      >
        <div style={{ flex: 1 }}>{this.props.sourceData.brand}</div>{" "}
        <div style={{ flex: 1 }}>••••{this.props.sourceData.last4}</div>
        <div style={{ flex: 1 }}>
          {this.props.sourceData.exp_month} / {this.props.sourceData.exp_year}
        </div>
        <div style={{ flex: 2, textAlign: "end", color: "#888" }}>
          added {moment(this.props.created).fromNow()}
        </div>
        {this.props.onRemove && (
          <div
            className={`hidden-actions ${
              this.state.isRemoving || this.state.error ? "visible" : ""
            }`}
          >
            {this.state.error ? (
              this.state.error.message
            ) : (
              <Button
                loading={this.state.isRemoving}
                minimal
                onClick={this.handleRemove}
                icon={"trash"}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export class _PaymentMethods extends React.Component {
  render() {
    if (!this.props.data || this.props.data.loading) {
      return (
        <RaisedContainer>
          <Spinner />
        </RaisedContainer>
      );
    }

    const sources = this.props.data.getSources.edges.map(({ node }) => node);
    return (
      <RaisedContainer>
        {sources.length > 0 ? (
          sources.map(s => (
            <PaymentSource
              {...s}
              onRemove={
                // don't allow removing all payment methods
                sources.length > 1
                  ? async e => {
                      await this.props.removeSource({
                        variables: { id: s.id }
                      });
                      this.props.data.refetch();
                    }
                  : undefined
              }
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #EEE",
              padding: "15px 20px"
            }}
          >
            <span>No payment methods found</span>
          </div>
        )}
        <AddPaymentSource
          onSourceCreated={async source => {
            await this.props.addSource({
              variables: { sourceId: source.id }
            });
            this.props.data.refetch();
          }}
        />
      </RaisedContainer>
    );
  }
}

export const PaymentMethods = compose(
  graphql(query),
  graphql(Mutations.addSource, { name: "addSource" }),
  graphql(Mutations.removeSource, { name: "removeSource" })
)(_PaymentMethods);
