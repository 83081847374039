import React from "react";
import { Button, Overlay, Classes } from "@blueprintjs/core";
import gql from "graphql-tag";
import { Query } from "react-apollo";

import "./plan.css";

/**
 * plan schema:
 * {
            "id": "UGxhbk5vZGU6Mg==",
            "active": true,
            "name": "",
            "description": "",
            "livemode": false,
            "billingScheme": "TIERED",
            "interval": "MONTH",
            "nickname": "Startup",
            "amount": null,
            "tiers": [
              {
                "up_to": 1000,
                "amount": null,
                "flat_amount": 50000
              },
              {
                "up_to": 20000,
                "amount": 50,
                "flat_amount": null
              },
              {
                "up_to": null,
                "amount": 30,
                "flat_amount": null
              }
            ],
            "trialPeriodDays": 5,
            "metadata": {
              "is_public": "yes"
            },
            "tiersMode": "GRADUATED",
            "statementDescriptor": ""
          }
        }
 */

const formatNumber = num => num.toLocaleString();

const formatMoney = amount => `$${(amount / 100).toFixed(2).toLocaleString()}`;

const sortPlans = plans =>
  plans.sort(
    (p1, p2) => Number(p1.metadata.sort_index) - Number(p2.metadata.sort_index)
  );

export const PlanPricing = ({
  tiers = [],
  tiersMode,
  interval,
  metadata,
  billingScheme,
  unitName = "user"
}) => {
  if (metadata && metadata.pricing_description) {
    return (
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        {metadata.pricing_description}
      </div>
    );
  }
  if (tiersMode === "GRADUATED")
    return (
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        {tiers.map((t, idx) => (
          <div>
            {t.flat_amount > 0 &&
              `${formatMoney(t.flat_amount)} / ${interval.toLowerCase()}`}
            {t.amount > 0 && `+ ${formatMoney(t.amount)} per active user`}
            {t.up_to
              ? idx > 0 && idx < tiers.length - 1
                ? ` for ${formatNumber(
                    tiers[idx - 1].up_to + 1
                  )} - ${formatNumber(t.up_to)} users`
                : ` for up to ${formatNumber(t.up_to)} users`
              : ` for additional users`}
          </div>
        ))}
      </div>
    );
  else if (tiersMode === "VOLUME") {
    return (
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        {tiers.map((t, idx) => (
          <div>
            {t.flat_amount > 0 &&
              `${formatMoney(t.flat_amount)} / ${interval.toLowerCase()}`}
            {t.amount > 0 && `${formatMoney(t.amount)} per active user`}
            {t.up_to && ` for up to ${formatNumber(t.up_to)} active users`}
          </div>
        ))}
      </div>
    );
  } else {
    console.warn("unsupported pricing mode");
    return <div style={{ marginTop: 8, marginBottom: 8 }} />;
  }
};

const Plan = ({ plan, onClick, ...rest }) => (
  <div
    className={`plan ${onClick ? "clickable" : ""}`}
    onClick={() => onClick && onClick(plan)}
    {...rest}
  >
    <h3>{plan.nickname}</h3>
    <h4>{plan.metadata.tagline}</h4>
    <PlanPricing {...plan} />
  </div>
);

export class PlanPicker extends React.Component {
  constructor(props) {
    super(props);

    let sortedPlans = sortPlans(props.plans);
    this.state = {
      selectedPlan: props.default || (sortedPlans.length && sortedPlans[0]),
      showOptions: false
    };

    if (props.onSelect && this.state.selectedPlan) {
      props.onSelect(this.state.selectedPlan);
    }
  }

  showPlans = () => {
    this.setState({ showOptions: true });
  };

  hidePlans = () => {
    this.setState({ showOptions: false });
  };

  selectPlan = selectedPlan => {
    this.setState({ selectedPlan, showOptions: false });

    if (this.props.onSelect) {
      this.props.onSelect(selectedPlan);
    }
  };

  render() {
    let { plans } = this.props;
    let sortedPlans = sortPlans(plans);
    const selectedPlan = this.state.selectedPlan;

    if (selectedPlan) {
      sortedPlans = [
        selectedPlan,
        ...sortedPlans.filter(p => p.nickname !== selectedPlan.nickname)
      ];
    }

    console.log(sortedPlans);

    console.log("selected", selectedPlan);

    return (
      <div style={{ position: "relative" }}>
        {selectedPlan && (
          <Plan
            key={selectedPlan.nickname}
            plan={selectedPlan}
            onClick={plans.length > 1 ? this.showPlans : undefined}
          />
        )}
        {plans.length > 1 && this.state.showOptions && (
          <div
            style={{
              position: "absolute",
              top: -10,
              left: 0,
              right: 0,
              zIndex: 999
            }}
          >
            {sortedPlans.map((plan, idx) => (
              <Plan
                key={plan.nickname}
                style={{ boxShadow: "0px 13px 55px -5px rgba(0,0,0,0.2)" }}
                plan={plan}
                onClick={this.selectPlan}
              />
            ))}
          </div>
        )}

        <Overlay
          backdropProps={{
            style: { backgroundColor: `rgba(139, 143, 146, 0.32)` }
          }}
          isOpen={plans.length > 1 && this.state.showOptions}
          onClose={this.hidePlans}
        >
          <div />
        </Overlay>
      </div>
    );
  }
}

const query = gql`
  {
    getPlans {
      edges {
        node {
          id
          active
          name
          description
          livemode
          billingScheme
          interval
          nickname
          amount
          #          aggregateUsage
          tiers
          trialPeriodDays
          metadata
          tiersMode
          statementDescriptor
        }
      }
    }
  }
`;

export const Plans = ({ children, ...rest }) => (
  <Query query={query} {...rest}>
    {x =>
      children({
        ...x,
        plans:
          x.data &&
          x.data.getPlans &&
          x.data.getPlans.edges.map(({ node }) => node)
      })
    }
  </Query>
);
